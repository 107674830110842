/* Enhanced Footer with advanced mobile optimization, visual effects, and improved UX */

.footer {
  background-color: #121a2f;
  background-image: linear-gradient(to bottom, #121a2f, #1a2745);
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  padding: 60px 0 0;
  /* Removed bottom padding to accommodate copyright bar */
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.15);
}

/* Decorative background pattern with improved performance */
.footer-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(rgba(0, 224, 255, 0.08) 2px, transparent 2px);
  background-size: 30px 30px;
  opacity: 0.3;
  z-index: -1;
  animation: footerPatternFade 20s infinite alternate;
  pointer-events: none;
  will-change: opacity;
  /* Performance optimization for animation */
}

@keyframes footerPatternFade {
  0%,
  100% {
    opacity: 0.15;
  }

  50% {
    opacity: 0.3;
  }
}

/* Container for footer content */
.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  /* Enable z-index stacking */
}

/* Statistics banner styling */
.stats-banner {
  background: rgba(13, 20, 39, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 20px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 224, 255, 0.1);
}

.stats-grid {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-value {
  font-size: 2.2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #00d8ff, #3a7bd5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
}

/* Grid layout for footer content */
.footer-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
  gap: 40px;
  margin-bottom: 40px;
}

/* Brand section styling with enhanced effects */
.footer-brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 15px;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
}

.footer-logo:hover {
  transform: translateY(-3px);
}

.footer-logo-svg {
  width: 36px;
  height: 36px;
  filter: drop-shadow(0 0 8px rgba(0, 224, 255, 0.5));
  transition: filter 0.3s ease;
}

.footer-logo:hover .footer-logo-svg {
  filter: drop-shadow(0 0 12px rgba(0, 224, 255, 0.7));
}

.footer-logo-text {
  font-size: 24px;
  font-weight: 700;
  margin-left: 10px;
  background: linear-gradient(135deg, #ffffff, #e6f7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.5px;
}

.footer-tagline {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
  line-height: 1.4;
}

/* Social media links with enhanced interactions */
.footer-social {
  display: flex;
  gap: 15px;
}

.footer-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(0, 224, 255, 0.1);
  border: 1px solid rgba(0, 224, 255, 0.2);
  border-radius: 50%;
  color: #00d8ff;
  font-size: 18px;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
  overflow: hidden;
}

.footer-social a::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(0, 224, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: width 0.4s ease, height 0.4s ease, opacity 0.4s ease;
}

.footer-social a:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.footer-social a:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 224, 255, 0.3);
  color: #ffffff;
}

.footer-social a:active {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 224, 255, 0.2);
}

/* Navigation columns with improved typography and effects */
.footer-nav h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  color: #fff;
  letter-spacing: 0.5px;
}

.footer-nav h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40px;
  height: 3px;
  background: linear-gradient(90deg, #00d8ff, transparent);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.footer-nav:hover h3::after {
  width: 60px;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-nav li {
  margin-bottom: 14px;
}

.footer-nav a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.25s ease;
  display: inline-block;
  position: relative;
}

.footer-nav a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #00d8ff, transparent);
  transition: width 0.3s ease;
}

.footer-nav a:hover {
  color: #00d8ff;
  transform: translateX(5px);
}

.footer-nav a:hover::before {
  width: 100%;
}

/* Support contact section with enhanced styling */
.footer-newsletter h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #fff;
  letter-spacing: 0.5px;
}

.footer-newsletter p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.newsletter-form {
  display: flex;
  gap: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 4px;
  transition: all 0.3s ease;
}

.newsletter-form:focus-within {
  border-color: #00d8ff;
  box-shadow: 0 0 0 2px rgba(0, 216, 255, 0.2);
}

.newsletter-form input {
  flex: 1;
  background: transparent;
  border: none;
  padding: 12px 16px;
  color: #fff;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;
}

.newsletter-form input:focus {
  color: #fff;
}

.newsletter-form input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease;
}

.newsletter-form input:focus::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter-form button {
  background: linear-gradient(135deg, #00d8ff, #3a7bd5);
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-form button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 216, 255, 0.3);
}

.newsletter-form button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 216, 255, 0.2);
}

/* Copyright bar - new section for better visibility */
.footer-copyright-bar {
  background-color: rgba(13, 17, 29, 0.95);
  padding: 15px 0;
  text-align: center;
  /* Changed from left to center for all devices */
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 20;
  margin-top: 40px;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  /* Added flex layout for better alignment */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.copyright-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.3px;
  text-align: center;
  /* Ensure text is centered */
  width: 100%;
  /* Take full width of container */
}

/* Scroll to top button with improved accessibility */
.scroll-top-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: rgba(0, 224, 255, 0.1);
  color: #00d8ff;
  border: 1px solid rgba(0, 224, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(100px);
  opacity: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  outline: none;
}

.scroll-top-btn.visible {
  transform: translateY(0);
  opacity: 1;
}

.scroll-top-btn:hover {
  background: rgba(0, 224, 255, 0.2);
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.scroll-top-btn:active {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.scroll-top-btn:focus {
  box-shadow: 0 0 0 3px rgba(0, 224, 255, 0.4);
}

/* Responsive design for tablets */
@media (max-width: 992px) {
  .footer-grid {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .footer-brand {
    grid-column: 1 / -1;
    align-items: center;
    text-align: center;
  }

  .footer-social {
    justify-content: center;
  }

  .footer-nav h3 {
    text-align: center;
    display: block;
  }

  .footer-nav h3::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .stats-grid {
    flex-wrap: wrap;
    gap: 20px;
  }

  .stat-item {
    flex: 1 0 30%;
  }

  .stat-value {
    font-size: 1.8rem;
  }
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .footer {
    padding: 40px 0 0;
  }

  .stats-banner {
    padding: 15px 0;
    margin-bottom: 30px;
  }

  .stats-grid {
    flex-direction: column;
    gap: 25px;
  }

  .stat-item {
    width: 100%;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 30px;
  }

  .footer-nav {
    text-align: center;
  }

  .footer-nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }

  .footer-nav li {
    margin-bottom: 0;
  }

  .footer-nav a {
    padding: 8px 12px;
    background: rgba(0, 224, 255, 0.05);
    border-radius: 6px;
    transition: all 0.2s ease;
  }

  .footer-nav a::before {
    display: none;
  }

  .footer-nav a:hover {
    transform: none;
    background: rgba(0, 224, 255, 0.1);
    color: #00d8ff;
  }

  .footer-newsletter {
    text-align: center;
  }

  .newsletter-form {
    margin: 0 auto;
    max-width: 90%;
  }

  .footer-copyright-bar {
    padding: 15px 20px;
    margin-top: 20px;
    margin-bottom: 8px;
    /* Space for mobile navigation */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copyright-text {
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    width: 100%;
  }

  .scroll-top-btn {
    width: 45px;
    height: 45px;
    right: 20px;
    bottom: 80px;
    /* Adjusted to avoid overlap with mobile nav */
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .footer {
    padding: 30px 0 0;
  }

  .newsletter-form {
    max-width: 100%;
  }

  .newsletter-form input {
    padding: 12px;
    font-size: 14px;
  }

  /* Enhance touch targets for small screens */
  .footer-social a,
  .footer-nav a {
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-copyright-bar {
    padding: 15px 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* Stack items if needed */
    position: relative;
    z-index: 30;
    /* Ensure it's above other elements */
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
  }

  .copyright-text {
    font-size: 12px;
    width: 100%;
    text-align: center;
    padding: 0 5px;
  }
}

/* Support for notched devices */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .footer-copyright-bar {
    padding-bottom: calc(15px + env(safe-area-inset-bottom, 0px));
  }

  .scroll-top-btn {
    bottom: calc(80px + env(safe-area-inset-bottom, 0px));
  }
}

/* Dark mode optimization */
@media (prefers-color-scheme: dark) {
  .footer {
    background-color: #0f1725;
    background-image: linear-gradient(to bottom, #0f1725, #16213e);
  }

  .footer-copyright-bar {
    background-color: rgba(10, 15, 27, 0.95);
  }
}

/* High contrast mode support */
@media (forced-colors: active) {

  .footer-social a,
  .footer-nav a,
  .newsletter-form input,
  .newsletter-form button,
  .scroll-top-btn {
    border: 2px solid currentColor;
  }

  .footer-logo-text,
  .stat-value {
    -webkit-text-fill-color: currentColor;
  }
}

/* Reduced motion preference support */
@media (prefers-reduced-motion: reduce) {

  .footer *,
  .footer *::before,
  .footer *::after {
    animation: none !important;
    transition: none !important;
    transform: none !important;
  }

  .scroll-top-btn.visible {
    opacity: 1;
  }
}
