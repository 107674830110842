/* MobileMenu.css - Enhanced mobile menu */

:root {
  --menu-bg: rgba(15, 23, 42, 0.98);
  --menu-overlay: rgba(0, 0, 0, 0.7);
  --menu-border: rgba(255, 255, 255, 0.1);
  --menu-text: rgba(255, 255, 255, 0.95);
  --menu-text-secondary: rgba(255, 255, 255, 0.7);
  --menu-accent: #00E0FF;
  --menu-accent-glow: rgba(0, 224, 255, 0.2);
  --menu-item-hover: rgba(255, 255, 255, 0.08);
  --menu-item-active: rgba(0, 224, 255, 0.15);
  
  /* Spacing */
  --menu-padding: 24px;
  --menu-item-padding: 16px;
  --menu-item-gap: 12px;
  
  /* Typography */
  --menu-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --menu-title-size: 1.5rem;
  --menu-item-size: 1rem;
  --menu-description-size: 0.9rem;
}

/* Overlay */
.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 998;
  cursor: pointer;
}

/* Menu Container */
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 380px;
  background: var(--menu-bg);
  z-index: 999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: -4px 0 24px rgba(0, 0, 0, 0.2);
}

/* Menu Header */
.mobile-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid var(--menu-border);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), transparent);
}

.mobile-menu-close {
  background: none;
  border: none;
  color: var(--menu-text);
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-menu-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--menu-text);
  margin: 0;
  background: linear-gradient(135deg, var(--menu-text), var(--menu-accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Search */
.mobile-menu-search {
  padding: 20px 24px;
  position: relative;
}

.search-container {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--menu-border);
  border-radius: 12px;
  padding: 12px 16px;
}

.search-container.focused {
  background: rgba(255, 255, 255, 0.08);
  border-color: var(--menu-accent);
  box-shadow: 0 0 0 2px var(--menu-accent-glow);
}

.search-icon {
  color: var(--menu-text-secondary);
  margin-right: 12px;
  font-size: 1.2rem;
}

.search-container input {
  flex: 1;
  background: none;
  border: none;
  color: var(--menu-text);
  font-size: var(--menu-item-size);
  padding: 0;
  outline: none;
  font-family: var(--menu-font);
}

.search-container input::placeholder {
  color: var(--menu-text-secondary);
}

/* Menu Content */
.mobile-menu-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  scrollbar-width: thin;
  scrollbar-color: var(--menu-accent) transparent;
  -webkit-overflow-scrolling: touch;
}

.mobile-menu-content::-webkit-scrollbar {
  width: 6px;
}

.mobile-menu-content::-webkit-scrollbar-track {
  background: transparent;
}

.mobile-menu-content::-webkit-scrollbar-thumb {
  background-color: var(--menu-accent);
  border-radius: 3px;
}

.mobile-nav-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
}

.mobile-nav-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  color: var(--menu-text);
  text-decoration: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid var(--menu-border);
  cursor: pointer;
}

.mobile-nav-item.selected {
  background: var(--menu-item-active);
  border-color: var(--menu-accent);
  box-shadow: 0 0 0 2px var(--menu-accent-glow);
}

.mobile-nav-item svg {
  width: 20px;
  height: 20px;
  color: var(--menu-accent);
}

.mobile-nav-item-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.mobile-nav-item-label {
  font-size: 1rem;
  font-weight: 500;
  color: var(--menu-text);
}

.mobile-nav-item-description {
  font-size: 0.9rem;
  color: var(--menu-text-secondary);
  line-height: 1.5;
}

/* Menu Footer */
.mobile-menu-footer {
  padding: 24px;
  border-top: 1px solid var(--menu-border);
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.02), transparent);
}

.mobile-menu-github,
.mobile-menu-get-started {
  padding: 14px;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.mobile-menu-github {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid var(--menu-border);
  color: var(--menu-text);
}

.mobile-menu-get-started {
  background: var(--menu-accent);
  color: #0B1221;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 224, 255, 0.2);
}

/* High Contrast Mode */
@media (forced-colors: active) {
  .mobile-menu {
    border-left: 2px solid CanvasText;
  }
  
  .mobile-nav-item {
    border: 1px solid transparent;
  }
  
  .mobile-nav-item.selected {
    border-color: CanvasText;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  :root {
    --menu-bg: rgba(15, 23, 42, 0.98);
    --menu-overlay: rgba(0, 0, 0, 0.7);
  }
}

/* Print Styles */
@media print {
  .mobile-menu,
  .mobile-menu-overlay {
    display: none !important;
  }
} 