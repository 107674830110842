/* App.css */
:root {
  --primary-color: #00E0FF;
  --secondary-color: #0070F3;
  --accent-color: #7928CA;
  --dark-bg: #0F172A;
  --light-bg: #1E293B;
  --text-primary: #F8FAFC;
  --text-secondary: #94A3B8;
  --bg-gradient: linear-gradient(135deg, var(--dark-bg), var(--light-bg));
  --border-light: rgba(0, 224, 255, 0.1);
  --primary-light: rgba(0, 224, 255, 0.5);
  --shadow-color: rgba(0, 224, 255, 0.4);
  --shadow-color-hover: rgba(0, 224, 255, 0.6);
  --spacing-xl: 2rem;
  --spacing-md: 1rem;
  --spacing-sm: 0.5rem;
  --radius-md: 6px;
  --transition-normal: 0.3s;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--bg-gradient);
  position: relative;
}

.app-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(0, 224, 255, 0.1), transparent 50%);
  pointer-events: none;
  z-index: 0;
}

.main-content {
  flex: 1;
  padding-bottom: var(--spacing-xl);
  position: relative;
  z-index: 1;
}

/* Enhanced Loader Styling */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--bg-gradient);
  position: relative;
  overflow: hidden;
}

.loader-container::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, var(--primary-color) 0%, transparent 70%);
  opacity: 0.1;
  animation: rotate 20s linear infinite;
}

.loader-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg);
  position: relative;
  z-index: 1;
}

.spinner-ring {
  width: 60px;
  height: 60px;
  border: 4px solid var(--border-light);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  border-left-color: var(--primary-color);
  animation: spin 1s linear infinite;
  position: relative;
}

.spinner-ring::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: var(--primary-light);
  animation: spin 2s linear infinite;
}

.spinner-text {
  font-size: 1.1rem;
  color: var(--primary-color);
  letter-spacing: 0.05em;
  animation: pulse-text 1.5s ease-in-out infinite;
  text-shadow: 0 0 10px rgba(0, 224, 255, 0.5);
}

/* Enhanced Error Boundary Styling */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: var(--spacing-xl);
  text-align: center;
  background: var(--bg-gradient);
  position: relative;
  overflow: hidden;
}

.error-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255, 0, 0, 0.1), transparent 70%);
  opacity: 0.5;
}

.error-container h2 {
  font-size: 2rem;
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  position: relative;
  z-index: 1;
}

.error-container p {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  position: relative;
  z-index: 1;
  max-width: 600px;
}

.error-details {
  background: rgba(26, 32, 44, 0.8);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin: var(--spacing-md) 0;
  max-width: 100%;
  overflow-x: auto;
  text-align: left;
  border: 1px solid var(--border-light);
  backdrop-filter: blur(10px);
}

.error-details pre {
  color: var(--text-primary);
  font-family: 'Fira Code', monospace;
  font-size: 0.9rem;
  margin: var(--spacing-sm) 0;
  white-space: pre-wrap;
}

.error-actions {
  display: flex;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xl);
  position: relative;
  z-index: 1;
}

.error-reload-btn,
.error-home-btn {
  padding: var(--spacing-sm) var(--spacing-lg);
  border: none;
  border-radius: var(--radius-md);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-normal);
  position: relative;
  overflow: hidden;
}

.error-reload-btn {
  background: var(--secondary-color);
  color: white;
}

.error-reload-btn:hover {
  background: var(--secondary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.error-home-btn {
  background: var(--text-primary);
  color: var(--dark-bg);
}

.error-home-btn:hover {
  background: var(--text-secondary);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

/* Enhanced 404 Page Styling */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  text-align: center;
  padding: var(--spacing-xl);
  position: relative;
  overflow: hidden;
}

.not-found-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, var(--accent-color) 0%, transparent 70%);
  opacity: 0.1;
}

.not-found-container h1 {
  font-size: 8rem;
  margin: 0;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1;
  position: relative;
  z-index: 1;
  text-shadow: 0 0 20px rgba(0, 224, 255, 0.3);
}

.not-found-container h2 {
  font-size: 2.5rem;
  margin: var(--spacing-md) 0;
  color: var(--text-primary);
  position: relative;
  z-index: 1;
}

.not-found-container p {
  font-size: 1.2rem;
  margin-bottom: var(--spacing-xl);
  color: var(--text-secondary);
  max-width: 500px;
  position: relative;
  z-index: 1;
}

.home-button {
  background: linear-gradient(90deg, var(--secondary-color), var(--accent-color));
  color: white;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--radius-md);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  transition: all var(--transition-normal);
  box-shadow: 0 4px 14px var(--shadow-color);
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.home-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.home-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px var(--shadow-color-hover);
}

.home-button:hover::before {
  left: 100%;
}

/* Enhanced Animations */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse-text {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Enhanced Responsive Adjustments */
@media (max-width: 768px) {
  .spinner-ring {
    width: 50px;
    height: 50px;
  }

  .spinner-text {
    font-size: 1rem;
  }

  .not-found-container h1 {
    font-size: 6rem;
  }

  .not-found-container h2 {
    font-size: 2rem;
  }

  .error-container {
    padding: var(--spacing-md);
  }

  .error-container h2 {
    font-size: 1.75rem;
  }

  .error-container p {
    font-size: 1rem;
  }

  .error-actions {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }

  .error-reload-btn,
  .error-home-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .spinner-ring {
    width: 40px;
    height: 40px;
  }

  .spinner-text {
    font-size: 0.9rem;
  }

  .not-found-container h1 {
    font-size: 4rem;
  }

  .not-found-container h2 {
    font-size: 1.5rem;
  }

  .not-found-container p {
    font-size: 1rem;
    padding: 0 var(--spacing-md);
  }

  .home-button {
    width: 100%;
    text-align: center;
  }
}
