/* Header.css - Enhanced with advanced features */

/* Z-Index Constants */
:root {
  --z-header: 1000;
  --z-header-scrolled: 1001;
}

/* ----- Global Header Styles ----- */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-header);
  background: rgba(18, 26, 47, 0.95);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
              background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
              box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, background-color, box-shadow;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header.scrolled {
  background: rgba(18, 26, 47, 0.98);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: var(--z-header-scrolled);
}

.header.hidden {
  transform: translateY(-100%);
}

.header-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

/* Brand styles */
.brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.brand-icon {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.brand-text {
  display: flex;
  flex-direction: column;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
}

.brand-badge {
  font-size: 0.75rem;
  color: #00E0FF;
  background: rgba(0, 224, 255, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  margin-top: 2px;
}

/* Navigation styles */
.nav {
  display: flex;
  align-items: center;
  gap: 32px;
}

.nav-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 400;
  transition: color 0.2s ease;
  padding: 4px 0;
  position: relative;
}

.nav-link:hover {
  color: #fff;
}

.nav-link.active {
  color: #fff;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
}

/* Header actions */
.header-actions {
  display: flex;
  align-items: center;
  gap: 24px;
}

.github-button {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  padding: 4px;
  transition: color 0.2s ease;
}

.github-button:hover {
  color: #fff;
}

.get-started-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: #00E5FF;
  color: #0B1221;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.get-started-button:hover {
  background: #00D1E6;
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.mobile-menu-toggle.visible {
  display: flex;
}

.mobile-menu-toggle:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.mobile-menu-toggle svg {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
}

.mobile-menu-toggle[aria-expanded="true"] {
  background: rgba(0, 229, 255, 0.15);
  border-color: rgba(0, 229, 255, 0.3);
  box-shadow: 0 4px 16px rgba(0, 229, 255, 0.2);
}

/* Active state */
.mobile-menu-toggle:active {
  transform: scale(0.95);
}

/* Focus state */
.mobile-menu-toggle:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 229, 255, 0.3);
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .mobile-menu-toggle,
  .mobile-menu-toggle svg {
    transition: none !important;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    height: 64px;
  }

  .header-inner {
    height: 64px;
    padding: 0 16px;
  }

  .brand-name {
    font-size: 1.25rem;
  }

  .brand-icon {
    width: 32px;
    height: 32px;
  }

  .nav {
    display: none;
  }

  .header-actions {
    display: none;
  }

  .mobile-menu-toggle {
    display: flex;
  }
}

/* High Contrast Mode */
@media (forced-colors: active) {
  .header {
    border-bottom: 2px solid CanvasText;
  }

  .nav-link {
    text-decoration: underline;
  }

  .get-started-button {
    border: 2px solid CanvasText;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .header {
    background: rgba(18, 26, 47, 0.95);
  }

  .header.scrolled {
    background: rgba(18, 26, 47, 0.98);
  }
}

/* Print Styles */
@media print {
  .header {
    position: static;
    background: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
}

