:root {
  /* Primary Colors */
  --primary-color: #00E0FF;
  --primary-dark: #00B8E6;
  --primary-light: #33E6FF;
  
  /* Secondary Colors */
  --secondary-color: #0070F3;
  --secondary-dark: #0058C1;
  --secondary-light: #3390F5;
  
  /* Accent Colors */
  --accent-color: #7928CA;
  --accent-dark: #6119A3;
  --accent-light: #9453D3;
  
  /* Background Colors */
  --dark-bg: #0F172A;
  --light-bg: #1E293B;
  --bg-gradient: linear-gradient(135deg, var(--dark-bg), var(--light-bg));
  
  /* Text Colors */
  --text-primary: #F8FAFC;
  --text-secondary: #94A3B8;
  --text-muted: #64748B;
  
  /* Border Colors */
  --border-light: rgba(255, 255, 255, 0.1);
  --border-dark: rgba(0, 0, 0, 0.2);
  
  /* Shadow Colors */
  --shadow-color: rgba(0, 112, 243, 0.4);
  --shadow-color-hover: rgba(0, 112, 243, 0.6);
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  
  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, var(--dark-bg), var(--light-bg));
  color: var(--text-primary);
  line-height: 1.6;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  width: 100%;
}

.section {
  padding: var(--spacing-xl) 0;
  border-bottom: 1px solid var(--border-light);
  position: relative;
  overflow: hidden;
}

.section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--primary-color), transparent);
  opacity: 0.3;
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: var(--spacing-xl);
  color: var(--primary-color);
  position: relative;
  display: inline-block;
}

.section h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background: var(--primary-color);
  border-radius: var(--radius-sm);
}

pre {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  border-radius: 8px;
  overflow-x: auto;
  margin: 1.5rem 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: 'Fira Code', monospace;
}

code {
  font-family: 'Fira Code', monospace;
  color: var(--text-primary);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--secondary-color);
}

/* Common animations */
@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from { 
    transform: translateY(20px);
    opacity: 0;
  }
  to { 
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Responsive utilities */
@media (max-width: 768px) {
  .section {
    padding: var(--spacing-lg) 0;
  }

  .section h2 {
    font-size: 2rem;
  }
  
  .container {
    padding: 0 var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .section h2 {
    font-size: 1.75rem;
  }
  
  .section h2::after {
    width: 40px;
  }
}
