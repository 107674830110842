/* MobileHelp.css - Modern and intelligent help center styles */

:root {
  /* Core colors */
  --help-bg: rgba(15, 23, 42, 0.98);
  --help-overlay: rgba(0, 0, 0, 0.7);
  --help-border: rgba(255, 255, 255, 0.1);
  --help-text: rgba(255, 255, 255, 0.9);
  --help-text-secondary: rgba(255, 255, 255, 0.6);
  --help-accent: #00E0FF;
  --help-accent-glow: rgba(0, 224, 255, 0.3);
  --help-search-bg: rgba(255, 255, 255, 0.05);
  --help-search-focus: rgba(0, 224, 255, 0.1);
  --help-section-bg: rgba(255, 255, 255, 0.02);
  --help-item-bg: rgba(255, 255, 255, 0.03);
  
  /* Animations */
  --help-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  --help-item-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --help-search-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Spacing */
  --help-padding: 16px;
  --help-item-padding: 12px;
  --help-section-gap: 8px;
  --help-item-gap: 4px;
  
  /* Typography */
  --help-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --help-title-size: 1.5rem;
  --help-section-size: 1.1rem;
  --help-item-size: 1rem;
  --help-description-size: 0.875rem;
  --help-content-size: 0.9rem;
  
  /* Shadows */
  --help-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --help-item-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --help-search-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Main Container */
.mobile-help {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--help-bg);
  transform: translateX(100%);
  transition: transform var(--help-transition);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mobile-help.active {
  transform: translateX(0);
}

/* Header */
.mobile-help-header {
  display: flex;
  align-items: center;
  padding: var(--help-padding);
  border-bottom: 1px solid var(--help-border);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), transparent);
}

.mobile-help-close {
  background: none;
  border: none;
  color: var(--help-text);
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: all var(--help-item-transition);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-help-close:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(90deg);
}

.mobile-help-title {
  margin: 0;
  margin-left: 16px;
  font-size: var(--help-title-size);
  font-weight: 600;
  color: var(--help-text);
  background: linear-gradient(135deg, var(--help-text), var(--help-accent));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Search */
.mobile-help-search {
  padding: var(--help-padding);
}

.search-container {
  display: flex;
  align-items: center;
  background: var(--help-search-bg);
  border-radius: 12px;
  padding: 12px;
  transition: all var(--help-search-transition);
}

.search-container.focused {
  background: var(--help-search-focus);
  box-shadow: var(--help-search-shadow);
}

.search-icon {
  color: var(--help-text-secondary);
  margin-right: 12px;
  transition: color var(--help-search-transition);
}

.search-container.focused .search-icon {
  color: var(--help-accent);
}

.search-container input {
  flex: 1;
  background: none;
  border: none;
  color: var(--help-text);
  font-size: var(--help-item-size);
  padding: 0;
  outline: none;
  font-family: var(--help-font);
}

.search-container input::placeholder {
  color: var(--help-text-secondary);
}

/* Content */
.mobile-help-content {
  flex: 1;
  overflow-y: auto;
  padding: var(--help-padding);
  display: flex;
  flex-direction: column;
  gap: var(--help-section-gap);
}

/* Sections */
.help-section {
  background: var(--help-section-bg);
  border-radius: 12px;
  overflow: hidden;
}

.help-section-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--help-item-padding);
  background: none;
  border: none;
  color: var(--help-text);
  cursor: pointer;
  transition: all var(--help-item-transition);
}

.help-section-header:hover {
  background: rgba(255, 255, 255, 0.05);
}

.help-section-header.expanded {
  background: rgba(255, 255, 255, 0.05);
}

.help-section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: var(--help-section-size);
  font-weight: 500;
}

.help-section-title svg {
  color: var(--help-accent);
}

.help-section-items {
  display: flex;
  flex-direction: column;
  gap: var(--help-item-gap);
  padding: var(--help-item-padding);
}

/* Items */
.help-item {
  background: var(--help-item-bg);
  border-radius: 8px;
  overflow: hidden;
}

.help-item-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--help-item-padding);
  background: none;
  border: none;
  color: var(--help-text);
  cursor: pointer;
  transition: all var(--help-item-transition);
}

.help-item-header:hover {
  background: rgba(255, 255, 255, 0.05);
}

.help-item-header.expanded {
  background: rgba(255, 255, 255, 0.05);
}

.help-item-title {
  flex: 1;
}

.help-item-title h3 {
  margin: 0;
  font-size: var(--help-item-size);
  font-weight: 500;
}

.help-item-title p {
  margin: 4px 0 0;
  font-size: var(--help-description-size);
  color: var(--help-text-secondary);
}

.help-item-content {
  padding: var(--help-item-padding);
  border-top: 1px solid var(--help-border);
  background: rgba(0, 0, 0, 0.2);
}

.help-content-heading {
  margin: 0 0 12px;
  font-size: var(--help-content-size);
  font-weight: 600;
  color: var(--help-text);
}

.help-content-text {
  margin: 0 0 12px;
  font-size: var(--help-content-size);
  color: var(--help-text-secondary);
  line-height: 1.5;
}

.help-content-code {
  margin: 0 0 12px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-family: monospace;
  font-size: var(--help-content-size);
  color: var(--help-text);
  overflow-x: auto;
}

/* Footer */
.mobile-help-footer {
  padding: var(--help-padding);
  border-top: 1px solid var(--help-border);
  display: flex;
  gap: 16px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.05), transparent);
}

.help-footer-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--help-text);
  text-decoration: none;
  font-size: var(--help-description-size);
  transition: all var(--help-item-transition);
}

.help-footer-link:hover {
  color: var(--help-accent);
}

.help-footer-link svg {
  width: 16px;
  height: 16px;
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  .mobile-help,
  .help-section-header,
  .help-item-header,
  .mobile-help-close,
  .search-container {
    transition: none !important;
  }
  
  .mobile-help-close:hover {
    transform: none;
  }
}

/* High Contrast Mode */
@media (forced-colors: active) {
  .mobile-help {
    border: 1px solid CanvasText;
  }
  
  .help-section,
  .help-item {
    border: 1px solid CanvasText;
  }
  
  .help-section-header:hover,
  .help-item-header:hover {
    border-color: Highlight;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  :root {
    --help-bg: rgba(15, 23, 42, 0.98);
    --help-overlay: rgba(0, 0, 0, 0.7);
  }
}

/* Print Styles */
@media print {
  .mobile-help {
    display: none !important;
  }
}

.help-content {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
} 